import { connectStateResults } from "react-instantsearch-dom"

export const IndexResults = connectStateResults(
  ({ searchResults, searchState, children }) =>
    searchResults &&
    searchResults.nbHits !== 0 &&
    searchState &&
    searchState.query
      ? children
      : null
)

export const AllResults = connectStateResults(
  ({ allSearchResults, children }) => {
    const hasResults =
      allSearchResults &&
      Object.values(allSearchResults).some(results => results.nbHits > 0)
    return !hasResults ? children : null
  }
)

export const indices = home => [
  { id: "Verbs", hits: home ? 5 : 10, className: "verb-results" },
  { id: "Vocabulary", hits: home ? 3 : 10 },
  { id: "Phrasebook", hits: home ? 3 : 10 },
]
