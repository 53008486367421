/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { ContentWrapper, Main, Section } from "gatsby-theme-elements"

import SearchBar from "../components/Search/SearchBar"

import SEO from "../components/Seo"

const SearchPage = () => {
  return (
    <Fragment>
      <SEO title="Search" />
      <ContentWrapper>
        <Main>
          <Section>
            <SearchBar />
          </Section>
        </Main>
      </ContentWrapper>
    </Fragment>
  )
}

export default SearchPage
