/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import styled from "@emotion/styled"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Index,
  Hits,
  Configure,
} from "react-instantsearch-dom"

import Results from "./Results"
import { AllResults, IndexResults, indices } from "../Search/common"
import { SearchIcon, CloseIcon, AlgoliaDarkIcon } from "../../assets/icons"

const searchClient = algoliasearch(
  "4JYVX00ZTJ",
  "84f2bc3c29d61feac2b894a503d00162"
)

const Search = React.memo(() => {
  return (
    <InstantSearch searchClient={searchClient} indexName="verbs">
      <SearchBar sx={{ height: [55, 78], m: ["30px auto 0", "50px auto 0"] }}>
        <SearchBox
          searchAsYouType={false}
          autoFocus={true}
          submit={<SearchIcon sx={{ height: [23, 26], fill: "primary" }} />}
          reset={<CloseIcon sx={{ height: [24, 30], fill: "primary" }} />}
          translations={{
            placeholder: `Search for a verb, topic, or translation...`,
          }}
        />
      </SearchBar>
      <div sx={{ textAlign: "right" }}>
        <AlgoliaLink
          href="https://algolia.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={AlgoliaDarkIcon} alt="Algolia.com" sx={{ height: 20 }} />
        </AlgoliaLink>
      </div>

      <HitArea>
        <h2>Search Results</h2>
        <div className="results-section">
          {indices(false).map(({ id, hits, className }) => (
            <Index key={id} indexName={id.toLowerCase()}>
              <IndexResults>
                <Configure hitsPerPage={hits} />
                <div
                  className={`hit-section ${className ? className : ""}`}
                  sx={{
                    ".ais-Hits-list": {
                      gridTemplateColumns: className
                        ? ["1fr", "repeat(4, 1fr)"]
                        : ["1fr", "repeat(3, 1fr)"],
                    },
                  }}
                >
                  <h4 sx={{ color: "primary" }}>{id}</h4>
                  <Hits hitComponent={Results} />
                </div>
              </IndexResults>
            </Index>
          ))}
        </div>
        <AllResults>
          <div className="no-results">No results...</div>
        </AllResults>
      </HitArea>
    </InstantSearch>
  )
})

const AlgoliaLink = styled.a`
  display: inline-block;
  margin: 20px 0;
`

const SearchBar = styled.div`
  display: flex;
  align-items: stretch;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  animation: shadow ease 0.8s forwards 0.2s;

  @keyframes shadow {
    0% {
      box-shadow: 1px 1px 25px 1px rgba(111, 111, 111, 0);
    }
    100% {
      box-shadow: 1px 1px 25px 1px rgba(111, 111, 111, 0.1);
    }
  }

  .ais-SearchBox {
    width: 100%;
  }

  .ais-SearchBox-form {
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;

    input {
      order: 2;
      flex: 1;
      background: none;
      -webkit-appearance: none;
      font-size: 20px;
      border: none;
      outline: none;
    }
  }

  .ais-SearchBox-reset {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    border: none;
    border-left: 1px solid #d6d6d6;
    background: #f7f7f7;
    padding: 0 15px;
  }

  .ais-SearchBox-submit {
    padding: 0 20px;
    order: 1;
    border: none;
    background: none;
  }

  @media screen and (max-width: 768px) {
    .ais-SearchBox-submit {
      padding: 0 12px;
    }

    .ais-SearchBox-form input {
      font-size: 16px;
    }
  }
`

const HitArea = styled.div`
  min-height: 80vh;

  h2 {
    font-size: 26px;
    color: #666666;
    margin-bottom: 20px;
    border-bottom: 1px solid gainsboro;
  }

  .no-results {
    grid-column: 1 / -1;
  }

  .hit-section {
    margin-bottom: 50px;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .ais-Hits-list {
    display: grid;
    grid-gap: 20px;
  }
`

export default Search
